<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
  <v-card>
      <v-card-title>
        {{ itemState }}
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4">
        <p>Укажите дату {{ toAction }} карты маршрута</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="runAction"
              :loading="loading"
              :disabled="!date"
            >
              {{ isIssue ? 'Прекратить' : 'Выдать'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

const ISSUE_STATUS_GUID = '43c5797c-0ea8-4aea-8f2d-e89becd54e7b';
const NEW_STATUS_GUID = 'c2cd5446-7849-4ab4-89f8-bcef841fd2a0';

import GeneralServices from '@/services/GeneralServices';

export default {
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  name: 'IssueCard',
  data: () => ({
    selected: null,
    date: new Date().toISOString().substr(0, 10),
    stateId: null,
    loading: false,
    dialog: true,
    isIssue: null,
    toAction: null,
    fromAction: null,
    successMsg: null,
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;
    if (!this.selected) {
      this.show = false;
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }

    this.stateId = this.selected.vccardregisterStateid;
    this.isIssue = this.stateId === ISSUE_STATUS_GUID ? true : false;
    this.toAction = this.isIssue ? 'прекращения действия' : 'выдачи';
    this.fromAction = this.isIssue ? 'выдачи' : 'прекращения действия';

    if (this.toAction === 'выдачи' && this.stateId !== NEW_STATUS_GUID) {
      this.dialog = false;
      jet.msg({
        text: `Действие ${this.toAction} карты доступно для статуса только "Новый"!`,
        color: 'warning'
      });
      return;
    }
  },
  computed: {
    itemState() {
      return this.isIssue ? 'Прекратить действие карты' : 'Выдать карту';
    },
  },
  methods: {
    async closeCard(cardId, servicedRouteId){
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
      
      const optionsQuery = {
        type: 'query',
        query: 'fbc65598-a2c4-45f3-8286-b2e69a678bdb.issueOrCloseCard',
        params: {
          cardId: cardId,
          servicedRouteId: servicedRouteId,
          tenantID: tenantId,   //this.selected._sec_attrsTenantid,
          userId: userId,       //this.selected._sec_attrsUserid,
          indate: this.date,
          action: 1
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        this.show = false;
        if (resp.error) {
          throw resp.error;
        }
        jet.msg({
          text: `Действие прекращения действия карты маршрута
            для карты ${this.selected.vccardregisterCardnumber}
            ${this.selected.vccardregisterCardseries}
            выполнено успешно!`
        });
        this.collection.refresh(cardId);
      } catch (e) {
        jet.msg({
          text: 'Не удалось прекратить действие карты',
          color: 'warning'
        });
        console.error('IssueCard: closeCard()', e);
        return;
      }
    },
    async issueCard(cardId, servicedRouteId){
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
      const optionsQuery = {
        type: 'query',
        query: 'fbc65598-a2c4-45f3-8286-b2e69a678bdb.issueOrCloseCard',
        params: {
          cardId: cardId,
          servicedRouteId: servicedRouteId,
          tenantId: tenantId,   //this.selected._sec_attrsTenantid,
          userId: userId,       //this.selected._sec_attrsUserid,
          indate: this.date,
          action: 0,
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        this.show = false;
        if (resp.error) {
          throw resp.error;
        }
        jet.msg({
          text: `Действие выдачи карты маршрута
            для карты ${this.selected.vccardregisterCardnumber}
            ${this.selected.vccardregisterCardseries}
            выполнено успешно!`
        });
        this.collection.refresh(cardId);
      } catch (e) {
        jet.msg({
          text: 'Не удалось выполнить действие по выдаче карты',
          color: 'warning'
        });
        console.error('IssueCard: issueCard()', e);
        return;
      }
    },
    async runAction(){
      const cardId = this.selected.vccardregisterId;
      const servicedRouteId = this.selected.vccardregisterServicedrouteid;

      if(this.isIssue){
        await this.closeCard(cardId, servicedRouteId);
      } else {
        await this.issueCard(cardId, servicedRouteId)
      }
      this.dialog = false;
    },
  }
};
</script>
